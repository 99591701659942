<script>
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";

export default {
  name: "create",
  data() {
    return {
      title: "Conectando con MAQRO",
      items: [
        {
          text: "MAQRO",
          href: "/",
          active: true,
        }
      ],
    };
  },
  components: {
    Layout, PageHeader
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <b-row>
      <b-col lg="12 p-0">
        <div class="card">
          <iframe
              src="https://sim.maqro.com.mx:45211/~servicio"
              ref="frame"
          >
          </iframe>
        </div>
      </b-col>
    </b-row>
  </Layout>
</template>

<style lang="scss" scoped>

  /* styles for desktop */
  @media (min-width: 1024px) {
    .container-fluid {
      padding: 0 !important;
    }

    .page-content {
      padding: 0 !important;
    }

    .card {
      & > iframe {
        height: 75vw
      }
    }
  }


  /* styles for tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    .card {
      height: 1024px;

      & > iframe {
        height: 100%
      }
    }
  }

  /* styles for mobile */
  @media (max-width: 767px) {
    .card {
      height: 980px;

      & > iframe {
        height: 100%
      }
    }
  }
</style>
